







import { Component, Vue } from "vue-property-decorator";
import RightTopCorner from "@/views/dashboard/components/RightTopCorner.vue";
import PanelGroup from "@/views/dashboard/components/PanelGroup.vue";

@Component({
  name: "DashboardUser",
  components: {
    PanelGroup,
    RightTopCorner,
  },
})
export default class extends Vue {
  private doRouteToSetupFilesClick() {
    this.$router.push("/system/installFile");
  }
}
