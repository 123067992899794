















import { Component, Vue } from "vue-property-decorator";
import PanelGroup, {
  ICardPanelProps,
} from "@/views/dashboard/components/PanelGroup.vue";
import RightTopCorner from "@/views/dashboard/components/RightTopCorner.vue";
import LineChart, { ILineChartData } from "./components/LineChart.vue";
import * as DashboardApi from "@/api/dashboard";
import * as DashboardEntry from "@/classes/dashboard";
import * as _ from "lodash";
import dayjs from "dayjs";

interface CardPanelProps extends ICardPanelProps {
  extLineChartData: ILineChartData;
}

@Component({
  name: "DashboardAdmin",
  components: {
    PanelGroup,
    RightTopCorner,
    LineChart,
  },
})
export default class extends Vue {
  private cardPanels: CardPanelProps[] = [
    {
      title: "已注册学校",
      name: "school",
      value: 0,
      svgIcon: "school",
      show: true,
      extLineChartData: {
        series: [],
      },
    },
    {
      title: "试卷已授权数",
      name: "testPaper",
      value: 0,
      svgIcon: "testPaper",
      show: true,
      extLineChartData: {
        series: [],
      },
    },
    {
      title: "今日练习人次",
      name: "mockExam",
      value: 0,
      svgIcon: "score",
      show: true,
      extLineChartData: {
        series: [],
      },
    },
  ];
  private lineChartData = this.cardPanels[0].extLineChartData;
  private activeCardPanel: CardPanelProps = this.cardPanels[0];

  private async __initTestPapersTotal() {
    const cardPanel = this.cardPanels.find((item) => item.name == "testPaper")!;
    cardPanel.value = 0;
    const { data } = await DashboardApi.getTestPapersTotal({});
    const items: DashboardEntry.TestPapersTotal[] = data.items;
    cardPanel.value = _.sumBy(items, (val) => {
      return val.authCount;
    });
    const lineChartData = cardPanel.extLineChartData;
    lineChartData.series.push(
      ...[
        {
          name: "授权数",
          data: [],
          itemStyle: {
            normal: {
              color: "#96CD78",
            },
          },
        },
      ]
    );
    items.forEach((item) => {
      lineChartData.series[0].data.push({
        name: item.schoolName,
        value: item.authCount,
      });
    });
  }

  private async __initMockExamsTotal() {
    const cardPanel = this.cardPanels.find((item) => item.name == "mockExam")!; // this.cardPanels[2];
    cardPanel.value = 0;
    const { data } = await DashboardApi.getMockExamsTotal({ withinDays: 70 });
    const items: DashboardEntry.MockExamsTotal[] = data.items;
    cardPanel.value = _.sumBy(items, (val) => {
      return dayjs().isSame(dayjs(val.examDate), "day") ? val.recordCount : 0;
    });
    const lineChartData = cardPanel.extLineChartData;
    lineChartData.series.push(
      ...[
        {
          name: "总人次",
          data: [],
          itemStyle: {
            normal: {
              color: "#5A6FC5",
            },
          },
        },
      ]
    );
    items
      .sort((a, b) => {
        if (a.examDate == b.examDate) {
          return 0;
        }
        return a.examDate > b.examDate ? -1 : 1;
      })
      .forEach((item) => {
        const fmtDate = dayjs(item.examDate).format("MM-DD");
        const idx = lineChartData.series[0].data.findIndex((val) => {
          return val.name == fmtDate;
        });
        if (idx >= 0) {
          const val = lineChartData.series[0].data[idx];
          val.value = val.value + item.recordCount;
        } else {
          lineChartData.series[0].data.push({
            name: fmtDate,
            value: item.recordCount,
          });
        }
      });
  }

  private async __initSchoolsTotal() {
    const cardPanel = this.cardPanels.find((item) => item.name == "school")!; // this.cardPanels[0];
    cardPanel.value = 0;
    const { data } = await DashboardApi.getSchoolsTotal({});
    const items: DashboardEntry.SchoolsTotal[] = data.items;
    cardPanel.value = _.sumBy(items, (val) => {
      return val.registeredCount;
    });
    const lineChartData = cardPanel.extLineChartData;
    lineChartData.series.splice(0, lineChartData.series.length);
    lineChartData.series.push(
      ...[
        {
          name: "已注册",
          data: [],
          itemStyle: {
            normal: {
              color: "#5A6FC5",
            },
          },
        },
        {
          name: "未注册",
          data: [],
          itemStyle: {
            normal: {
              color: "#F48250",
            },
          },
        },
        {
          name: "已签约",
          data: [],
          itemStyle: {
            normal: {
              color: "#96CD78",
            },
          },
        },
        {
          name: "将到期",
          data: [],
          itemStyle: {
            normal: {
              color: "#F5C85A",
            },
          },
        },
        {
          name: "已过期",
          data: [],
          itemStyle: {
            normal: {
              color: "#E66264",
            },
          },
        },
      ]
    );
    items.forEach((item) => {
      lineChartData.series[0].data.push({
        name: item.areaName,
        value: item.registeredCount,
      });
      lineChartData.series[1].data.push({
        name: item.areaName,
        value: item.schoolCount - item.registeredCount,
      });
      lineChartData.series[2].data.push({
        name: item.areaName,
        value: item.contractedCount,
      });
      lineChartData.series[3].data.push({
        name: item.areaName,
        value: item.nearContractEndDateCount,
      });
      lineChartData.series[4].data.push({
        name: item.areaName,
        value: item.expireContractEndDateCount,
      });
    });
  }

  private handlePanelGroupItemClick(type: string) {
    const cardPanel = this.cardPanels.find((item) => item.name == type)!;
    this.activeCardPanel = cardPanel;
    switch (type.toLocaleLowerCase()) {
      case "school": {
        this.__initSchoolsTotal();
        this.lineChartData = cardPanel.extLineChartData;
        break;
      }
      case "testpaper": {
        this.__initTestPapersTotal();
        this.lineChartData = cardPanel.extLineChartData;
        break;
      }
      case "mockexam": {
        this.__initMockExamsTotal();
        this.lineChartData = cardPanel.extLineChartData;
        break;
      }
      default: {
        alert(type);
      }
    }

    //this.lineChartData = lineChartData[type]
  }

  private doRouteToSetupFilesClick() {
    this.$router.push("/system/installFile");
  }

  mounted() {
    this.__initSchoolsTotal();
    this.__initTestPapersTotal();
    this.__initMockExamsTotal();
    (this.$refs["panelGroup"] as PanelGroup).doClick(this.activeCardPanel.name);
    //alert("mounted");
  }
  activated() {
    //(this.$refs["panelGroup"] as PanelGroup).doClick(this.activeCardPanel.name);
    //this.handlePanelGroupItemClick(this.activeCardPanel.name);
    //alert("activated");
  }
  deactived() {}
}
