













































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import CountTo from "vue-count-to";
import * as _ from "lodash";

export interface ICardPanelProps {
  title: string;
  name: string;
  value: number;
  svgIcon: string;
  svgClass?: string;
  show: boolean;
  active?: boolean;
}

@Component({
  name: "PanelGroup",
  components: {
    CountTo,
  },
})
export default class extends Vue {
  @Prop({ default: () => [] }) private cardPanels!: ICardPanelProps[];
  @Emit("click") private handleClick(name: string) {
    this.activeCardPanel = this.cardPanels.find((item) => item.name == name)!;
    this.cardPanels
      .filter((item) => {
        return item.name != name;
      })
      .forEach((item) => {
        this.$set(item, "active", false);
      });
    this.$set(this.activeCardPanel, "active", true);
  }
  private activeCardPanel!: ICardPanelProps;
  private cardPanelActiveClass: string = "";
  private selected(item: ICardPanelProps): boolean {
    let result: boolean = false;
    if (this.activeCardPanel) {
      result = item.name == this.activeCardPanel.name;
    }
    if (result) {
      console.log(`result,${this.activeCardPanel.name},${item.name}`);
    } else {
      console.log(`result,${item.name}`);
    }
    return result;
  }

  public doClick(name: string) {
    this.handleClick(name);
  }
}
