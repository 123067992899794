import { render, staticRenderFns } from "./platformAdmin.vue?vue&type=template&id=eff68acc&scoped=true&"
import script from "./platformAdmin.vue?vue&type=script&lang=ts&"
export * from "./platformAdmin.vue?vue&type=script&lang=ts&"
import style0 from "./platformAdmin.vue?vue&type=style&index=0&id=eff68acc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eff68acc",
  null
  
)

export default component.exports