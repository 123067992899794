




import echarts, { EChartOption } from "echarts";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ResizeMixin from "@/components/Charts/mixins/resize";
import * as _ from "lodash";

export interface IPieChartData {
  series: {
    name: string;
    data: { name: string; value: number; itemStyle?: Object }[];
    itemStyle?: Object;
  }[];
}

const animationDuration = 2600;

@Component({
  name: "PieChart",
})
export default class extends mixins(ResizeMixin) {
  @Prop({ required: true }) private chartData!: IPieChartData;
  @Prop({ default: "chart" }) private className!: string;
  @Prop({ default: "100%" }) private width!: string;
  @Prop({ default: "300px" }) private height!: string;

  @Watch("chartData", { deep: true })
  private onChartDataChange(value: IPieChartData) {
    this.setOptions(value);
  }

  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  private initChart() {
    this.chart = echarts.init(this.$el as HTMLDivElement, "macarons");
    this.setOptions(this.chartData);
  }
  private setOptions(chartData: IPieChartData) {
    let legendData: string[] = [];
    let series: any[] = [];
    for (let i = 0; i < chartData.series.length; i++) {
      legendData.push(...chartData.series[i].data.map((item) => item.name));
      series.push(
        _.merge(
          {
            name: "",
            type: "pie",
            roseType: "radius",
            radius: [15, 95],
            center: ["50%", "42%"],
            data: [],
            animationEasing: "cubicInOut",
            animationDuration: 2600,
          },
          chartData.series[i]
        )
      );
    }

    const option = {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)",
      },
      legend: {
        left: "center",
        bottom: "10",
        data: legendData,
      },
      series: series,
    };
    this.chart!.setOption(option as EChartOption<EChartOption.SeriesPie>);
  }
}
