





































import { Component, Vue } from "vue-property-decorator";
import RightTopCorner from "@/views/dashboard/components/RightTopCorner.vue";
import PanelGroup, {
  ICardPanelProps,
} from "@/views/dashboard/components/PanelGroup.vue";
import LineChart, { ILineChartData } from "./components/LineChart.vue";
import * as DashboardApi from "@/api/dashboard";
import * as DashboardEntry from "@/classes/dashboard";
import * as _ from "lodash";
import dayjs from "dayjs";
import { getExamProjectList } from "@/api/examProject";
import { ExamProject } from "@/tool/interface-index";
import { CommentExamProject } from "@/classes/exam-comment";
import BarChart, { IBarChartData } from "./components/BarChart.vue";
import PieChart, { IPieChartData } from "./components/PieChart.vue";
import ExamSchoolStatisticsTable from "./components/FormalExamSchoolStatisticsTable.vue";
import * as CommentApi from "@/api/examComment";
import {
  CommentParams,
  ExamSchoolsStatisticsReport,
  ExamSchoolStatistics,
} from "@/classes/exam-comment";

const examType = "formalExamMode";
const examTypes = ["", "formalExamMode"];
const examStartTimeWithinDays = 0;

interface CardPanelProps extends ICardPanelProps {
  extLineChartData: ILineChartData;
  extBarChartData: IBarChartData;
  extPieChartData: IPieChartData;
}

@Component({
  name: "DashboardFormalExamAdmin",
  components: {
    PanelGroup,
    RightTopCorner,
    LineChart,
    BarChart,
    PieChart,
    ExamSchoolStatisticsTable,
  },
})
export default class extends Vue {
  private cardPanels: CardPanelProps[] = [
    {
      title: "已注册学校",
      name: "school",
      value: 0,
      svgIcon: "school",
      svgClass: "",
      show: false,
      extLineChartData: {
        series: [],
      },
      extBarChartData: {
        series: [],
      },
      extPieChartData: {
        series: [],
      },
    },
  ];

  private examProjectList: CommentExamProject[] = [];
  private lineChartData = this.cardPanels[0].extLineChartData;
  private barChartData = this.cardPanels[0].extBarChartData;
  private pieChartData = this.cardPanels[0].extPieChartData;
  private activeCardPanel: CardPanelProps = this.cardPanels[0];

  private async __initSchoolsTotal() {
    const cardPanel: CardPanelProps = this.cardPanels.find((item) => {
      return item.name == "school";
    })!; // this.cardPanels[0];
    cardPanel.value = 0;
    const { data } = await DashboardApi.getSchoolsTotal({});
    const items: DashboardEntry.SchoolsTotal[] = data.items;
    cardPanel.value = _.sumBy(items, (val) => {
      return val.registeredCount;
    });
    const lineChartData = cardPanel.extLineChartData;
    lineChartData.series.splice(0, lineChartData.series.length);
    lineChartData.series.push(
      ...[
        {
          name: "已注册",
          data: [],
          itemStyle: {
            normal: {
              color: "#5A6FC5",
            },
          },
        },
        /*
        {
          name: "未注册",
          data: [],
          itemStyle: {
            normal: {
              color: "#F48250",
            },
          },
        },
        {
          name: "已签约",
          data: [],
          itemStyle: {
            normal: {
              color: "#96CD78",
            },
          },
        },
        {
          name: "将到期",
          data: [],
          itemStyle: {
            normal: {
              color: "#F5C85A",
            },
          },
        },
        {
          name: "已过期",
          data: [],
          itemStyle: {
            normal: {
              color: "#E66264",
            },
          },
        },
        */
      ]
    );
    items.forEach((item) => {
      lineChartData.series[0].data.push({
        name: item.areaName,
        value: item.registeredCount,
      });
      /*
      lineChartData.series[1].data.push({
        name: item.areaName,
        value: item.schoolCount - item.registeredCount,
      });
      lineChartData.series[2].data.push({
        name: item.areaName,
        value: item.contractedCount,
      });
      lineChartData.series[3].data.push({
        name: item.areaName,
        value: item.nearContractEndDateCount,
      });
      lineChartData.series[4].data.push({
        name: item.areaName,
        value: item.expireContractEndDateCount,
      });
      */
    });
  }

  private async __init_examProjectList() {
    try {
      this.cardPanels.splice(1, this.cardPanels.length - 1);

      this.examProjectList.splice(0, this.examProjectList.length);
      const { data } = await CommentApi.getExamProjectList({
        examTypes: examTypes,
        pageSize: 999,
        curPage: 1,
        examStartTimeWithinDays: examStartTimeWithinDays + 1,
      });

      this.examProjectList.push(
        ...data.items.map((item: any) => {
          return item;
        })
      );

      this.cardPanels.push(
        ...data.items.map((item: any) => {
          return {
            title: item.examProjectName,
            name: item.examProjectId,
            value: item.examStudentCount || 0,
            svgIcon: "score",
            svgClass: "",
            show: true,
            extLineChartData: {
              series: [],
            },
            extBarChartData: {
              series: [],
            },
            extPieChartData: {
              series: [],
            },
          };
        })
      );
      console.log(`cardPanels: ${JSON.stringify(this.cardPanels)}`);
    } finally {
      //
    }
  }

  private loadingExamProject: boolean = false;
  private examSchoolsStatistics: ExamSchoolsStatisticsReport = {
    items: [],
  };
  private async getExamProjectData(examProjectId: string) {
    try {
      if (this.loadingExamProject) {
        return;
      }
      this.loadingExamProject = true;
      //
      const cardPanel = this.cardPanels.find(
        (item) => item.name == examProjectId
      )!;
      cardPanel.value = 0;
      //
      const params = {
        pageSize: 999,
        curPage: 1,
        keyWords: "",
        examSchoolId: "",
        examProjectId: examProjectId,
        examType: examType,
        examStartTimeWithinDays: examStartTimeWithinDays,
      };
      const { data } = await CommentApi.getExamSchoolsReport(params);

      this.examSchoolsStatistics = data;
      this.examSchoolsStatistics.items.splice(0, 0);

      cardPanel.value = _.sumBy(this.examSchoolsStatistics!.items, (val) => {
        return val.examStudentCount;
      });

      const pieChartData = cardPanel.extPieChartData;
      pieChartData.series.splice(0, pieChartData.series.length);
      pieChartData.series.push({
        name: "状态统计",
        data: [
          {
            name: "未考",
            value: 0,
            itemStyle: {
              color: "#5A6FC5",
            },
          },
          /*
          {
            name: "重置",
            value: 0,
            itemStyle: {
              color: "#E66264",
            },
          },
          */
          {
            name: "未完成",
            value: 0,
            itemStyle: {
              color: "#F5C85A",
            },
          },
          {
            name: "已完成",
            value: 0,
            itemStyle: {
              color: "#96CD78",
            },
          },
        ],
      });

      const barChartData = cardPanel.extBarChartData;
      barChartData.series.splice(0, barChartData.series.length);
      barChartData.series.push(
        ...[
          {
            name: "未考",
            data: [],
            itemStyle: {
              normal: {
                color: "#5A6FC5",
              },
            },
          },
          /*
          {
            name: "重置",
            data: [],
            itemStyle: {
              normal: {
                color: "#E66264",
              },
            },
          },
          */
          {
            name: "未完成",
            data: [],
            itemStyle: {
              normal: {
                color: "#F5C85A",
              },
            },
          },
          {
            name: "已完成",
            data: [],
            itemStyle: {
              normal: {
                color: "#96CD78",
              },
            },
          },
        ]
      );

      this.examSchoolsStatistics!.items.forEach(
        (item: ExamSchoolStatistics) => {
          barChartData.series[0].data.push({
            dataKey: item.examSchoolId,
            name: item.examSchoolName,
            value: item.examNull,
          });
          pieChartData.series[0].data[0].value =
            pieChartData.series[0].data[0].value + item.examNull;
          //
          /*
          barChartData.series[1].data.push({
            dataKey: item.examSchoolId,
            name: item.examSchoolName,
            value: item.examNone,
          });
          pieChartData.series[0].data[1].value =
            pieChartData.series[0].data[1].value + item.examNone;
          */
          //
          barChartData.series[1].data.push({
            dataKey: item.examSchoolId,
            name: item.examSchoolName,
            value: item.examHas + item.examNone,
          });
          pieChartData.series[0].data[1].value =
            pieChartData.series[0].data[1].value + item.examHas + item.examNone;
          //
          barChartData.series[2].data.push({
            dataKey: item.examSchoolId,
            name: item.examSchoolName,
            value: item.examEnded,
          });
          pieChartData.series[0].data[2].value =
            pieChartData.series[0].data[2].value + item.examEnded;
        }
      );
    } finally {
      this.loadingExamProject = false;
    }
  }

  private handleBarChartClick(params: any) {
    if (params.componentType === "series") {
      const name = params.name;
      const rawBase = this.examSchoolsStatistics!.items.find(
        (item) => item.examSchoolName == name
      )!;
      this.$router.push({
        path: `/examManagement/examSchoolStatisticsDetails`,
        query: {
          examProjectId: rawBase.examProjectId,
          examSchoolId: rawBase.examSchoolId,
          examType: "formalExamMode",
        },
      });

      //alert(`您点击了${name}类别,${JSON.stringify(rawBase)}`);
    }
  }

  private async handlePanelGroupItemClick(type: string) {
    const cardPanel = this.cardPanels.find((item) => item.name == type)!;
    this.activeCardPanel = cardPanel;
    //
    if (type == "school") {
      //
    } else {
      const examProjectId = type;
      await this.getExamProjectData(examProjectId);
    }
    this.barChartData = cardPanel.extBarChartData;
    this.lineChartData = cardPanel.extLineChartData;
    this.pieChartData = cardPanel.extPieChartData;
  }

  private doRouteToSetupFilesClick() {
    this.$router.push("/system/installFile");
  }

  mounted() {
    //this.__initSchoolsTotal();
    this.__init_examProjectList().then(() => {
      if (this.cardPanels.length > 1) {
        //this.handlePanelGroupItemClick(this.cardPanels[1].name);
        (this.$refs["panelGroup"] as PanelGroup).doClick(this.cardPanels[1].name);
      }
    });
  }
  activated() {
    //this.handlePanelGroupItemClick(this.activeCardPanel.name);
    (this.$refs["panelGroup"] as PanelGroup).doClick(this.activeCardPanel.name);
  }
  deactived() {}
}
