




import echarts, { EChartOption } from "echarts";
import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ResizeMixin from "@/components/Charts/mixins/resize";
import * as _ from "lodash";

export interface ILineChartData {
  series: {
    name: string;
    data: { name: string; value: number }[];
    itemStyle?: Object;
  }[];
}

@Component({
  name: "LineChart",
})
export default class extends mixins(ResizeMixin) {
  @Prop({ required: true }) private chartData!: ILineChartData;
  @Prop({ default: "chart" }) private className!: string;
  @Prop({ default: "100%" }) private width!: string;
  @Prop({ default: "350px" }) private height!: string;

  @Watch("chartData", { deep: true })
  private onChartDataChange(value: ILineChartData) {
    this.setOptions(value);
  }

  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  private initChart() {
    this.chart = echarts.init(this.$el as HTMLDivElement, "macarons");
    this.setOptions(this.chartData);
  }

  private setOptions(chartData: ILineChartData) {
    let xAxisData: string[] = [];
    let legendData: string[] = [];
    let series: any[] = [];
    for (let i = 0; i < chartData.series.length; i++) {
      legendData.push(chartData.series[i].name);
      xAxisData.push(
        ...chartData.series[i].data.map((item) => {
          return item.name;
        })
      );
      series.push(
        _.merge(
          {
            name: "",
            itemStyle: {
              //color: "#FF005A",
              lineStyle: {
                //color: "#FF005A",
                width: 2,
              },
            },
            smooth: true,
            type: "line",
            //stack: "Total",
            areaStyle: {},
            emphasis: {
              focus: "series",
            },
            data: [],
            animationDuration: 2800,
            animationEasing: "cubicInOut",
          },
          chartData.series[i]
        )
      );
    }
    xAxisData = _.uniq(xAxisData);
    //
    if (this.chart) {
      const option = {
        xAxis: {
          data: xAxisData, //["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          axisLabel: {
            rotate: 30,
          },
          boundaryGap: false,
          axisTick: {
            show: false,
          },
        },
        grid: {
          left: 10,
          right: 10,
          bottom: 20,
          top: 30,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
          padding: 8,
        },
        yAxis: {
          axisTick: {
            show: false,
          },
        },
        legend: {
          data: legendData, //["expected", "actual"],
        },
        series: series /*[
          {
            name: "expected",
            itemStyle: {
              color: "#FF005A",
              lineStyle: {
                color: "#FF005A",
                width: 2,
              },
            },
            smooth: true,
            type: "line",
            data: chartData.expectedData,
            animationDuration: 2800,
            animationEasing: "cubicInOut",
          },
          {
            name: "actual",
            smooth: true,
            type: "line",
            itemStyle: {
              color: "#3888fa",
              lineStyle: {
                color: "#3888fa",
                width: 2,
              },
              areaStyle: {
                color: "#f3f8ff",
              },
            },
            data: chartData.actualData,
            animationDuration: 2800,
            animationEasing: "quadraticOut",
          },
        ],*/,
      };
      this.chart.clear();
      this.chart.setOption(option as EChartOption<EChartOption.SeriesLine>);
    }
  }
}
