














import { Component, Vue } from "vue-property-decorator";
import RightTopCorner from "@/views/dashboard/components/RightTopCorner.vue";
import PanelGroup, {
  ICardPanelProps,
} from "@/views/dashboard/components/PanelGroup.vue";
import LineChart, { ILineChartData } from "./components/LineChart.vue";
import * as DashboardApi from "@/api/dashboard";
import * as DashboardEntry from "@/classes/dashboard";
import * as _ from "lodash";
import dayjs from "dayjs";

interface CardPanelProps extends ICardPanelProps {
  extLineChartData: ILineChartData;
}

@Component({
  name: "DashboardContentAdmin",
  components: {
    PanelGroup,
    RightTopCorner,
    LineChart,
  },
})
export default class extends Vue {
  private cardPanels: CardPanelProps[] = [
    {
      title: "已注册学校",
      name: "school",
      value: 0,
      svgIcon: "school",
      svgClass: "",
      show: false,
      extLineChartData: {
        series: [],
      },
    },
  ];

  private lineChartData = this.cardPanels[0].extLineChartData;
  private activeCardPanel: CardPanelProps = this.cardPanels[0];

  private async __initSchoolsTotal() {
    const cardPanel: CardPanelProps = this.cardPanels.find((item) => {
      return item.name == "school";
    })!; // this.cardPanels[0];
    cardPanel.value = 0;
    const { data } = await DashboardApi.getSchoolsTotal({});
    const items: DashboardEntry.SchoolsTotal[] = data.items;
    cardPanel.value = _.sumBy(items, (val) => {
      return val.registeredCount;
    });
    const lineChartData = cardPanel.extLineChartData;
    lineChartData.series.splice(0, lineChartData.series.length);
    lineChartData.series.push(
      ...[
        {
          name: "已注册",
          data: [],
          itemStyle: {
            normal: {
              color: "#5A6FC5",
            },
          },
        },
        /*
        {
          name: "未注册",
          data: [],
          itemStyle: {
            normal: {
              color: "#F48250",
            },
          },
        },
        {
          name: "已签约",
          data: [],
          itemStyle: {
            normal: {
              color: "#96CD78",
            },
          },
        },
        {
          name: "将到期",
          data: [],
          itemStyle: {
            normal: {
              color: "#F5C85A",
            },
          },
        },
        {
          name: "已过期",
          data: [],
          itemStyle: {
            normal: {
              color: "#E66264",
            },
          },
        },
        */
      ]
    );
    items.forEach((item) => {
      lineChartData.series[0].data.push({
        name: item.areaName,
        value: item.registeredCount,
      });
      /*
      lineChartData.series[1].data.push({
        name: item.areaName,
        value: item.schoolCount - item.registeredCount,
      });
      lineChartData.series[2].data.push({
        name: item.areaName,
        value: item.contractedCount,
      });
      lineChartData.series[3].data.push({
        name: item.areaName,
        value: item.nearContractEndDateCount,
      });
      lineChartData.series[4].data.push({
        name: item.areaName,
        value: item.expireContractEndDateCount,
      });
      */
    });
  }

  private handlePanelGroupItemClick(type: string) {
    const cardPanel = this.cardPanels.find((item) => item.name == type)!;
    this.activeCardPanel = cardPanel;
  }

  private doRouteToSetupFilesClick() {
    this.$router.push("/system/installFile");
  }

  mounted() {
    //this.__initSchoolsTotal();
  }
  activated() {
    this.handlePanelGroupItemClick(this.activeCardPanel.name);
  }
  deactived() {}
}
