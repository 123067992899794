import { render, staticRenderFns } from "./PanelGroup.vue?vue&type=template&id=b8f37f36&scoped=true&"
import script from "./PanelGroup.vue?vue&type=script&lang=ts&"
export * from "./PanelGroup.vue?vue&type=script&lang=ts&"
import style0 from "./PanelGroup.vue?vue&type=style&index=0&id=b8f37f36&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8f37f36",
  null
  
)

export default component.exports