import request from '@/utils/request';

export const getSchoolsTotal = (params: {
    areaIds?: string[]
}) => request({
    // 获取学校统计
    url: '/dashboard/v1/schoolsTotal',
    method: 'GET',
    params
})

export const getMockExamsTotal = (params: {
    areaIds?: string[],
    withinDays?: number,
    withinRangeDays?: number[]
}) => request({
    // 获取模考统计
    url: '/dashboard/v1/mockExamsTotal',
    method: 'GET',
    params
})

export const getTestPapersTotal = (params: {
    areaIds?: string[],
   
}) => request({
    // 获取试卷统计
    url: '/dashboard/v1/testPapersTotal',
    method: 'GET',
    params
})