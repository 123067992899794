




































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { getTransactions } from "@/api/transactions";
import {
  CommentParams,
  ExamSchoolsStatisticsReport,
  ExamSchoolStatistics,
} from "@/classes/exam-comment";
import * as CommentApi from "@/api/examComment";
import dayjs from "dayjs";
import { exportJson2Excel } from "@/utils/excel";
import { formatJson } from "@/utils";
import * as _ from "lodash";
import * as ResApi from "@/api/examFileResource";

@Component({
  name: "MockExamSchoolStatisticsTable",
  filters: {
    transactionStatusFilter: (status: string) => {
      const statusMap: { [key: string]: string } = {
        success: "success",
        pending: "danger",
      };
      return statusMap[status];
    },
    orderNoFilter: (str: string) => str.substring(0, 30),
    // Input 10000 => Output "10,000"
    toThousandFilter: (num: number) => {
      return (+num || 0)
        .toString()
        .replace(/^-?\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
    },
  },
})
export default class extends Vue {
  @Prop({ required: true }) private items!: ExamSchoolStatistics[];
  @Prop({ default: "" }) private className!: string;
  @Prop({ default: "100%" }) private width!: string;
  @Prop({ default: "300px" }) private height!: string;

  @Watch("items", { deep: true })
  private onItemsChange(value: ExamSchoolStatistics[]) {
    //this.setOptions(value);
    this.$nextTick(() => {
      (this.$refs.examSchoolStatisticsTable as any).doLayout();
    });
  }

  created() {
    this.fetchData();
  }

  mounted() {
    //console.log(`examSchool`)
  }

  private async fetchData() {
    //const { data } = await getTransactions({ /* Your params here */ })
    //this.items = data.items.slice(0, 8)
  }

  private lastExamStateTimeFormatter(row: any, column: any) {
    if (dayjs(row.lastExamStateTime).isValid()) {
      return dayjs(row.lastExamStateTime).format("YYYY-MM-DD HH:mm:ss");
    }
    return "无";
  }

  private doShowDetailsClick(row: ExamSchoolStatistics) {
    this.$router.push({
      path: "/mockExamManagement/examProjectList",
      query: {
        examSchoolId: row.examSchoolId,
        examType: "mockExamMode", //formalExamMode
      },
    });
  }

  private async exportExamNulls(row: ExamSchoolStatistics): Promise<any[]> {
    const { data } = await CommentApi.getExamSchoolStatistics(
      row.examProjectId,
      row.examSchoolId
    );
    let examNulls: any[] = _.get(data, "examStudents", []).filter((v: any) => {
      let result1 = v!.statisticsTag.toLowerCase() == "examNull".toLowerCase();
      const tag = v!.statisticsTag.toLowerCase();
      const tag1 = "examNone".toLowerCase();
      const tag2 = "examHas".toLowerCase();
      let result2 = (tag == tag1 || tag == tag2) && !v.examAnswerPackageFileUrl;
      return result1 || result2;
    });

    const filterVal = [
      "examSchoolName",
      "schoolName",
      "className",
      "examAccount",
      "examStudentName",
      "statisticsTag",
    ];
    const data1: any[] = formatJson(
      filterVal,
      examNulls.map((item) => {
        let statisticsTag: string = "";
        switch (item.statisticsTag.toLowerCase()) {
          case "examNull".toLowerCase(): {
            statisticsTag = "缺考";
            break;
          }
          case "examNone".toLowerCase(): {
            statisticsTag = "已重置";
            break;
          }
          case "examHas".toLowerCase(): {
            statisticsTag = "未考完";
            break;
          }
          default: {
            statisticsTag = item.statisticsTag;
          }
        }
        return _.merge(item, {
          examSchoolName: row.examSchoolName,
          statisticsTag,
        });
      })
    );
    this.examNullsToExcel(
      data1,
      `${_.get(data, "examProjectName", "")}_${_.get(
        data,
        "examSchoolName",
        ""
      )}_缺考_名单`
    );
    return data1;
  }

  private examNullsToExcel(data: any[], fileName: string) {
    const tHeader = ["考点", "学校", "班级", "考号", "姓名", "状态"];
    exportJson2Excel(tHeader, data, fileName);
  }

  private async exportExamFaileds(row: ExamSchoolStatistics) {
    const { data } = await CommentApi.getExamSchoolStatistics(
      row.examProjectId,
      row.examSchoolId
    );
    let examFaileds: any[] = _.get(data, "examStudents", []).filter(
      (v: any) => {
        const tag = v!.statisticsTag.toLowerCase();
        const tag1 = "examNone".toLowerCase();
        const tag2 = "examHas".toLowerCase();
        return tag == tag1 || tag == tag2;
      }
    );
    for (let i = 0; i < examFaileds.length; i++) {
      console.log(JSON.stringify(examFaileds[i]));
      let examAnswerPackageDetailFileNames =
        examFaileds[i]?.examAnswerPackageDetailFileNames || [];
      let answerFileCount = examAnswerPackageDetailFileNames.filter(
        (val: string) => {
          return val != "test.mp3";
        }
      ).length;
      let hasTestFile = examAnswerPackageDetailFileNames.some((val: string) => {
        return val == "test.mp3";
      });
      _.merge(examFaileds[i], {
        hasTestFile: hasTestFile ? "有" : "无",
        answerFileCount,
        mark: "深圳：中学14个录音、小学8个录音可视为数据完整",
      });
    }

    const tHeader = [
      "班级",
      "考号",
      "姓名",
      "状态",
      "数据包",
      "作答录音数",
      "测音文件",
      "备注",
    ];
    const filterVal = [
      "className",
      "examAccount",
      "examStudentName",
      "statisticsTag",
      "examAnswerPackageFileUrl",
      "answerFileCount",
      "hasTestFile",
      "mark",
    ];
    const data1 = formatJson(filterVal, examFaileds);
    exportJson2Excel(
      tHeader,
      data1,
      `${_.get(row, "examProjectName", "")}_${_.get(
        row,
        "examSchoolName",
        ""
      )}_未结束考试名单`
    );
  }

  private async downloadExamStatisticsReport(params: {
    examProjectName: string;
    examSchoolName: string;
    examProjectId: string;
    examSchoolId: string;
  }) {
    try {
      const fileName = `${params.examProjectName}(${params.examSchoolName})考生成绩明细[(不含缺考)].xlsx`;
      const baseUrl: string = process.env.VUE_APP_BASE_API || "";
      const examStudentsReportShortFileUrl = `${baseUrl}${
        baseUrl.endsWith("/") ? "" : "/"
      }v1/examComment/statistics/download/examStudentsReport?examProjectId=${
        params.examProjectId
      }&examSchoolId=${params.examSchoolId}&fileName=${fileName}`;

      ResApi.download(`${examStudentsReportShortFileUrl}`, {}, fileName);
    } catch (error) {
      //
    }
  }

  private async handleExportCommand(
    command: string,
    row: ExamSchoolStatistics
  ) {
    switch (command.toLocaleLowerCase()) {
      case "ExportExamScores".toLocaleLowerCase(): {
        await this.downloadExamStatisticsReport({
          examProjectId: row.examProjectId,
          examProjectName: row.examProjectName,
          examSchoolId: row.examSchoolId,
          examSchoolName: row.examSchoolName,
        });
        break;
      }
      case "ExportExamFaileds".toLocaleLowerCase(): {
        await this.exportExamFaileds(row);
        break;
      }
      case "ExportExamNulls".toLocaleLowerCase(): {
        await this.exportExamNulls(row);
        break;
      }
    }
  }
}
