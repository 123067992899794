
















import { Component, Vue } from "vue-property-decorator";
import DashboardAdmin from "./admin.vue";
import DashboardTeacher from "./teacher.vue";
import DashboardMockExamAdmin from "./mockExamAdmin.vue";
import DashboardFormalExamAdmin from "./formalExamAdmin.vue";
import DashboardPlatformAdmin from "./platformAdmin.vue";
import DashboardContentAdmin from "./contentAdmin.vue";
import DashboardUser from "./user.vue";
import * as _ from "lodash";
import { UserModule } from "@/store/modules/user";
import { appTitle } from "@/utils/public-consts";
import * as consts from "@/utils/public-consts";

@Component({
  name: "Dashboard",
  components: {
    DashboardAdmin,
    DashboardTeacher,
    DashboardMockExamAdmin,
    DashboardFormalExamAdmin,
    DashboardPlatformAdmin,
    DashboardContentAdmin,
    DashboardUser,
  },
})
export default class extends Vue {
  private currentRoleId: consts.RoleId = consts.RoleId.unknown;
  private get onlyRoleId(): consts.RoleId {
    const arr = this.$route.path.split("/");
    if (arr.length > 0) {
      const idx = consts.RoleIds.findIndex((val) => {
        return val == arr[arr.length - 1];
      });
      if (idx >= 0) {
        return arr[arr.length - 1] as consts.RoleId;
      }
      //console.log(`onlyRoleId: ${JSON.stringify(arr)}, idx: ${idx}`);
    }
    return consts.RoleId.unknown;
  }

  private get roles() {
    return UserModule.roles;
  }

  private get displayRoles(): {
    roleId: string;
    roleName: string;
    disabled: boolean;
  }[] {
    return consts.roles.filter((item) => {
      return this.roles.some((val) => item.roleId == val);
    });
  }

  private get currentRole(): string {
    let roleId: consts.RoleId = consts.RoleId.unknown;
    if (this.onlyRoleId) {
      this.currentRoleId = this.onlyRoleId;
    }
    if (this.currentRoleId) {
      roleId = this.currentRoleId;
    } else {
      if (this.roles.includes(consts.RoleId.admin)) {
        roleId = consts.RoleId.admin;
      } else if (this.roles.includes(consts.RoleId.teacher)) {
        roleId = consts.RoleId.teacher;
      } else if (this.roles.includes(consts.RoleId.formalExamAdmin)) {
        roleId = consts.RoleId.formalExamAdmin;
      } else if (this.roles.includes(consts.RoleId.mockExamAdmin)) {
        roleId = consts.RoleId.mockExamAdmin;
      } else if (this.roles.includes(consts.RoleId.platformAdmin)) {
        roleId = consts.RoleId.platformAdmin;
      } else if (this.roles.includes(consts.RoleId.contentAdmin)) {
        roleId = consts.RoleId.contentAdmin;
      }
    }
    switch (roleId) {
      case consts.RoleId.admin: {
        return "dashboard-admin";
      }
      case consts.RoleId.teacher: {
        return "dashboard-teacher";
      }
      case consts.RoleId.formalExamAdmin: {
        return "dashboard-formal-exam-admin";
      }
      case consts.RoleId.mockExamAdmin: {
        return "dashboard-mock-exam-admin";
      }
      case consts.RoleId.platformAdmin: {
        return "dashboard-platform-admin";
      }
      case consts.RoleId.contentAdmin: {
        return "dashboard-content-admin";
      }
      default: {
        return "dashboard-user";
      }
    }
  }

  private async __init() {}

  created() {}

  mounted() {
    this.__init();
  }
}
