




import echarts, { EChartOption } from "echarts";
import { Component, Prop, Watch, Emit } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import ResizeMixin from "@/components/Charts/mixins/resize";
import * as _ from "lodash";

const animationDuration = 6000;

export interface IBarChartData {
  series: {
    name: string;
    data: { name: string; value: number; dataKey?: string }[];
    itemStyle?: Object;
  }[];
}

@Component({
  name: "BarChart",
})
export default class extends mixins(ResizeMixin) {
  @Prop({ required: true }) private chartData!: IBarChartData;
  @Prop({ default: "chart" }) private className!: string;
  @Prop({ default: "100%" }) private width!: string;
  @Prop({ default: "300px" }) private height!: string;

  @Watch("chartData", { deep: true })
  private onChartDataChange(value: IBarChartData) {
    this.setOptions(value);
  }
  @Emit("click") handleClick(params: any) {}

  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  }

  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  }

  private initChart() {
    this.chart = echarts.init(this.$el as HTMLDivElement, "macarons");
    this.setOptions(this.chartData);
    this.chart.on("click", this.handleClick);
  }

  private setOptions(chartData: IBarChartData) {
    let xAxisData: string[] = [];
    let legendData: string[] = [];
    let series: any[] = [];
    for (let i = 0; i < chartData.series.length; i++) {
      legendData.push(chartData.series[i].name);
      xAxisData.push(
        ...chartData.series[i].data.map((item) => {
          return item.name;
        })
      );
      series.push(
        _.merge(
          {
            name: "",
            type: "bar",
            stack: "vistors",
            //barWidth: '60%',
            data: [],
            animationDuration,
          },
          chartData.series[i]
        )
      );
    }
    xAxisData = _.uniq(xAxisData);
    //
    if (this.chart) {
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          top: 30,
          left: "2%",
          right: "2%",
          bottom: "3%",
          containLabel: true,
        },
        legend: {
          data: legendData, //["expected", "actual"],
        },
        xAxis: [
          {
            type: "category",
            data: xAxisData, //["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            axisLabel: {
              rotate: 30,
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisTick: {
              show: false,
            },
          },
        ],
        series: series /*[
        {
          name: "pageA",
          type: "bar",
          stack: "vistors",
          // barWidth: '60%',
          data: [79, 52, 200, 334, 390, 330, 220],
          animationDuration,
        },
        {
          name: "pageB",
          type: "bar",
          stack: "vistors",
          // barWidth: '60%',
          data: [80, 52, 200, 334, 390, 330, 220],
          animationDuration,
        },
        {
          name: "pageC",
          type: "bar",
          stack: "vistors",
          // barWidth: '60%',
          data: [30, 52, 200, 334, 390, 330, 220],
          animationDuration,
        },
      ]*/,
      };
      this.chart!.setOption(option as EChartOption<EChartOption.SeriesBar>);
    }
  }
}
