










































import { Component, Emit, Prop, Vue } from "vue-property-decorator";

class Props {}

@Component({
  name: "RightTopCorner",
})
export default class extends Vue {
  @Prop({
    default: () => {
      return new Props();
    },
  })
  private props!: Props;
  @Emit("click") private handleClick() {}
}
