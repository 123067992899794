













































import { Component, Vue } from "vue-property-decorator";
import RightTopCorner from "@/views/dashboard/components/RightTopCorner.vue";
import PanelGroup, {
  ICardPanelProps,
} from "@/views/dashboard/components/PanelGroup.vue";
import LineChart, { ILineChartData } from "./components/LineChart.vue";
import BarChart, { IBarChartData } from "./components/BarChart.vue";
import PieChart, { IPieChartData } from "./components/PieChart.vue";
import ExamProjectStatisticsTable from "./components/ExamProjectStatisticsTable.vue";
import ExamSchoolStatisticsTable from "./components/MockExamSchoolStatisticsTable.vue";
import * as DashboardApi from "@/api/dashboard";
import * as DashboardEntry from "@/classes/dashboard";
import * as _ from "lodash";
import dayjs from "dayjs";
import * as CommentApi from "@/api/examComment";
import {
  CommentParams,
  ExamSchoolsStatisticsReport,
  ExamSchoolStatistics,
} from "@/classes/exam-comment";
import { PageHeader } from "element-ui";

const examType = "mockExamMode";
const examTypes = ["mockExamMode"];
const examStartTimeWithinDays = 0;

interface CardPanelProps extends ICardPanelProps {
  extLineChartData: ILineChartData;
  extBarChartData: IBarChartData;
  extPieChartData: IPieChartData;
}

@Component({
  name: "DashboardMockExamAdmin",
  components: {
    PanelGroup,
    RightTopCorner,
    LineChart,
    BarChart,
    PieChart,
    ExamProjectStatisticsTable,
    ExamSchoolStatisticsTable,
  },
})
export default class extends Vue {
  private cardPanels: CardPanelProps[] = [
    {
      title: "已注册学校",
      name: "school",
      value: 0,
      svgIcon: "school",
      svgClass: "",
      show: false,
      extLineChartData: {
        series: [],
      },
      extBarChartData: {
        series: [],
      },
      extPieChartData: {
        series: [],
      },
    },
    /*
    {
      title: "已授权试卷",
      name: "testPaper",
      value: 100,
      svgIcon: "testPaper",
      svgClass: "",
      extLineChartData: {
        series: [],
      },
      extBarChartData: {
        series: [],
      },
      extPieChartData: {
        series: [],
      },
    },
    */
    {
      title: "今日练习人次",
      name: "mockExam0",
      value: 0,
      svgIcon: "score",
      svgClass: "",
      show: true,
      extLineChartData: {
        series: [],
      },
      extBarChartData: {
        series: [],
      },
      extPieChartData: {
        series: [],
      },
    },
    {
      title: "昨日练习人次",
      name: "mockExam1",
      value: 0,
      svgIcon: "score",
      svgClass: "",
      show: true,
      extLineChartData: {
        series: [],
      },
      extBarChartData: {
        series: [],
      },
      extPieChartData: {
        series: [],
      },
    },
    {
      title: "前日练习人次",
      name: "mockExam2",
      value: 0,
      svgIcon: "score",
      svgClass: "",
      show: true,
      extLineChartData: {
        series: [],
      },
      extBarChartData: {
        series: [],
      },
      extPieChartData: {
        series: [],
      },
    },
  ];

  private lineChartData = this.cardPanels[0].extLineChartData;
  private barChartData = this.cardPanels[0].extBarChartData;
  private pieChartData = this.cardPanels[0].extPieChartData;
  private activeCardPanel: CardPanelProps = this.cardPanels[0];

  private async __initTestPapersTotal() {}

  private async __initMockExamsTotal(cardPanel: CardPanelProps) {
    const withinRangeDays: number[] = [];
    switch (cardPanel.name) {
      case "mockExam0": {
        withinRangeDays.push(examStartTimeWithinDays + 0);
        break;
      }
      case "mockExam1": {
        withinRangeDays.push(examStartTimeWithinDays + 1);
        break;
      }
      case "mockExam2": {
        withinRangeDays.push(examStartTimeWithinDays + 2);
        break;
      }
    }
    cardPanel.value = 0;
    const { data } = await DashboardApi.getMockExamsTotal({ withinRangeDays });
    const items: DashboardEntry.MockExamsTotal[] = data.items;
    cardPanel.value = _.sumBy(items, "recordCount");
  }

  private async __initSchoolsTotal() {
    const cardPanel: CardPanelProps = this.cardPanels.find((item) => {
      return item.name == "school";
    })!; // this.cardPanels[0];
    cardPanel.value = 0;
    const { data } = await DashboardApi.getSchoolsTotal({});
    const items: DashboardEntry.SchoolsTotal[] = data.items;
    cardPanel.value = _.sumBy(items, (val) => {
      return val.registeredCount;
    });
    const lineChartData = cardPanel.extLineChartData;
    lineChartData.series.splice(0, lineChartData.series.length);
    lineChartData.series.push(
      ...[
        {
          name: "已注册",
          data: [],
          itemStyle: {
            normal: {
              color: "#5A6FC5",
            },
          },
        },
        /*
        {
          name: "未注册",
          data: [],
          itemStyle: {
            normal: {
              color: "#F48250",
            },
          },
        },
        {
          name: "已签约",
          data: [],
          itemStyle: {
            normal: {
              color: "#96CD78",
            },
          },
        },
        {
          name: "将到期",
          data: [],
          itemStyle: {
            normal: {
              color: "#F5C85A",
            },
          },
        },
        {
          name: "已过期",
          data: [],
          itemStyle: {
            normal: {
              color: "#E66264",
            },
          },
        },
        */
      ]
    );
    items.forEach((item) => {
      lineChartData.series[0].data.push({
        name: item.areaName,
        value: item.registeredCount,
      });
      /*
      lineChartData.series[1].data.push({
        name: item.areaName,
        value: item.schoolCount - item.registeredCount,
      });
      lineChartData.series[2].data.push({
        name: item.areaName,
        value: item.contractedCount,
      });
      lineChartData.series[3].data.push({
        name: item.areaName,
        value: item.nearContractEndDateCount,
      });
      lineChartData.series[4].data.push({
        name: item.areaName,
        value: item.expireContractEndDateCount,
      });
      */
    });
  }

  private loadingExamProject: boolean = false;
  private examSchoolsStatistics: ExamSchoolsStatisticsReport = {
    items: [],
  };

  private async getExamProjectData(
    cardPanel: CardPanelProps,
    examStartTimeWithinRangeDays: number[]
  ) {
    try {
      if (this.loadingExamProject) {
        return;
      }
      this.loadingExamProject = true;
      //
      cardPanel.value = 0;
      //
      const params = {
        pageSize: 999,
        curPage: 1,
        keyWords: "",
        examSchoolId: "",
        examProjectId: "",
        examType: examType,
        examStartTimeWithinRangeDays,
      };
      const { data } = await CommentApi.getExamSchoolsReport(params);
      //this.examSchoolsStatistics = data;
      this.examSchoolsStatistics.items.splice(
        0,
        this.examSchoolsStatistics.items.length
      );
      _.forEach(
        _.groupBy(data.items, "examSchoolName"),
        (value: ExamSchoolStatistics[], key: string) => {
          console.log(`group, key: ${key}, value: ${value}`);
          const tmp = {
            examProjectName: "",
            examSchoolName: "",
            examTimeCount: 0,
            examStudentCount: 0,
            minExamAccount: "",
            maxExamAccount: "",
            minSchoolName: "",
            maxSchoolName: "",
            multiSchool: false,
            examNull: 0,
            examNone: 0,
            examHas: 0,
            examEnded: 0,
            pkgCount: 0,
            firstExamStateTime: null,
            lastExamStateTime: null,
            examProjectId: "",
            examSchoolId: "",
            examStudents: [],
          };
          value.forEach((item) => {
            tmp.examSchoolId = item.examSchoolId;
            tmp.examSchoolName = item.examSchoolName;
            tmp.examTimeCount = tmp.examTimeCount + item.examTimeCount;
            tmp.examStudentCount = tmp.examStudentCount + item.examStudentCount;
            tmp.examNull = tmp.examNull + item.examNull;
            tmp.examNone = tmp.examNone + item.examNone;
            tmp.examHas = tmp.examHas + item.examHas;
            tmp.examEnded = tmp.examEnded + item.examEnded;
            tmp.pkgCount = tmp.pkgCount + item.pkgCount;
            if (
              !tmp.lastExamStateTime ||
              (item.lastExamStateTime as any) > (tmp.lastExamStateTime as any)
            ) {
              tmp.lastExamStateTime = item.lastExamStateTime as any;
            }
          });
          this.examSchoolsStatistics.items.push(tmp as ExamSchoolStatistics);
        }
      );
      this.examSchoolsStatistics.items.splice(0, 0);

      cardPanel.value = _.sumBy(this.examSchoolsStatistics!.items, (val) => {
        return val.examStudentCount;
      });

      const pieChartData = cardPanel.extPieChartData;
      pieChartData.series.splice(0, pieChartData.series.length);
      pieChartData.series.push({
        name: "状态统计",
        data: [
          {
            name: "未考",
            value: 0,
            itemStyle: {
              color: "#5A6FC5",
            },
          },
          {
            name: "重置",
            value: 0,
            itemStyle: {
              color: "#E66264",
            },
          },
          {
            name: "未完成",
            value: 0,
            itemStyle: {
              color: "#F5C85A",
            },
          },
          {
            name: "已完成",
            value: 0,
            itemStyle: {
              color: "#96CD78",
            },
          },
        ],
      });

      const barChartData = cardPanel.extBarChartData;
      barChartData.series.splice(0, barChartData.series.length);
      barChartData.series.push(
        ...[
          {
            name: "未考",
            data: [],
            itemStyle: {
              normal: {
                color: "#5A6FC5",
              },
            },
          },
          {
            name: "重置",
            data: [],
            itemStyle: {
              normal: {
                color: "#E66264",
              },
            },
          },
          {
            name: "未完成",
            data: [],
            itemStyle: {
              normal: {
                color: "#F5C85A",
              },
            },
          },
          {
            name: "已完成",
            data: [],
            itemStyle: {
              normal: {
                color: "#96CD78",
              },
            },
          },
        ]
      );

      pieChartData.series[0].data[0].value = 0;
      pieChartData.series[0].data[1].value = 0;
      pieChartData.series[0].data[2].value = 0;
      pieChartData.series[0].data[3].value = 0;
      _.uniqBy(
        this.examSchoolsStatistics!.items.map((item) => {
          return {
            examSchoolId: item.examSchoolId,
            examSchoolName: item.examSchoolName,
          };
        }),
        "examSchoolId"
      ).forEach((item) => {
        barChartData.series[0].data.push({
          dataKey: item.examSchoolId,
          name: item.examSchoolName,
          value: 0,
        });
        
        //
        barChartData.series[1].data.push({
          dataKey: item.examSchoolId,
          name: item.examSchoolName,
          value: 0,
        });
        
        //
        barChartData.series[2].data.push({
          dataKey: item.examSchoolId,
          name: item.examSchoolName,
          value: 0,
        });
        
        //
        barChartData.series[3].data.push({
          dataKey: item.examSchoolId,
          name: item.examSchoolName,
          value: 0,
        });
        
        this.examSchoolsStatistics!.items.filter((val) => {
          return val.examSchoolId == item.examSchoolId;
        }).forEach((val: ExamSchoolStatistics) => {
          const data0 = barChartData.series[0].data.find(
            (item) => item.dataKey == val.examSchoolId
          )!;
          data0.value = data0.value + val.examNull;
          pieChartData.series[0].data[0].value =
            pieChartData.series[0].data[0].value + val.examNull;
          //
          const data1 = barChartData.series[1].data.find(
            (item) => item.dataKey == val.examSchoolId
          )!;
          data1.value = data1.value + val.examNone;
          pieChartData.series[0].data[1].value =
            pieChartData.series[0].data[1].value + val.examNone;
          //
          const data2 = barChartData.series[2].data.find(
            (item) => item.dataKey == val.examSchoolId
          )!;
          data2.value = data2.value + val.examHas;
          pieChartData.series[0].data[2].value =
            pieChartData.series[0].data[2].value + val.examHas;
          //
          const data3 = barChartData.series[3].data.find(
            (item) => item.dataKey == val.examSchoolId
          )!;
          data3.value = data3.value + val.examEnded;
          pieChartData.series[0].data[3].value =
            pieChartData.series[0].data[3].value + val.examEnded;
        });
      });
    } finally {
      this.loadingExamProject = false;
    }
  }

  private handleBarChartClick(params: any) {
    if (params.componentType === "series") {
      const name = params.name;
      const rawBase = this.examSchoolsStatistics!.items.find(
        (item) => item.examSchoolName == name
      )!;
    }
  }

  private async handlePanelGroupItemClick(type: string) {
    const cardPanel = this.cardPanels.find((item) => item.name == type)!;
    this.activeCardPanel = cardPanel;
    //
    switch (type.toLocaleLowerCase()) {
      case "school".toLocaleLowerCase(): {
        break;
      }
      case "mockExam0".toLocaleLowerCase(): {
        //今天
        await this.getExamProjectData(cardPanel, [examStartTimeWithinDays + 0]);
        break;
      }
      case "mockExam1".toLocaleLowerCase(): {
        //昨天
        await this.getExamProjectData(cardPanel, [examStartTimeWithinDays + 1]);
        break;
      }
      case "mockExam2".toLocaleLowerCase(): {
        //前天
        await this.getExamProjectData(cardPanel, [examStartTimeWithinDays + 2]);
        break;
      }
    }
    //
    this.barChartData = cardPanel.extBarChartData;
    this.lineChartData = cardPanel.extLineChartData;
    this.pieChartData = cardPanel.extPieChartData;
  }

  private doRouteToSetupFilesClick() {
    this.$router.push("/system/installFile");
  }

  mounted() {
    //this.__initSchoolsTotal();
    this.__initTestPapersTotal();
    for (let i = 0; i < 3; i++) {
      const cardPanel = this.cardPanels.find(
        (item) => item.name == `mockExam${i}`
      )!;
      this.__initMockExamsTotal(cardPanel).then(() => {
        if (this.cardPanels.length > 1) {
          (this.$refs["panelGroup"] as PanelGroup).doClick(
            this.cardPanels[1].name
          );
          //this.handlePanelGroupItemClick(this.cardPanels[1].name);
        }
      });
    }
  }
  activated() {
    this.handlePanelGroupItemClick(this.activeCardPanel.name);
  }
  deactived() {}
}
